<template v-if="userInterviewData">
  <div>
    <b-embed type="iframe"  :src="userInterviewData.join_url" allowfullscreen/>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import interviewStoreModule from "../../interviewStoreModule";

export default {

  setup() {
    const userInterviewData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-interviews";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, interviewStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-interviews/fetchInterview", router.currentRoute.params.id)
      .then((response) => {
        userInterviewData.value = response.data.data;
        alert(userInterviewData.value.join_url)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userInterviewData.value = undefined;
        }
      });

    return {
      userInterviewData,
    };
  },
  data() {
    return {
      };
  },
  created() {
    // get route params from
    this.interviewId = router.currentRoute.params.id;
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
</style>
